<template>
  <div class="main-testimonial">

    <!-- <div style="color: rgb(70, 0, 7); margin-top: 1%; margin-bottom:1%; letter-spacing: 1px">
      HEAR FROM OUR PREMIUM SUBSCRIBERS
    </div> -->

    <v-sheet elevation="8">

      <!-- <v-slide-group v-model="model" 
      class="">
       <v-slide-group-item v-for="item in items" :key="item.id" > -->
      <v-row>
        <v-col lg="1" sm="12" md="12" cols="0"></v-col>
        <v-col class="heading-title" lg="5" sm="12" md="12" cols="12 ">
          <span>PREMIUM SUBSCRIBERS</span>
        </v-col>
        <v-col lg="5" sm="12" md="12">
          <v-carousel style="max-width: 100%;" cycle :show-arrows="false" hide-delimiter-background
            delimiter-icon="mdi-circle">
            <div class="d-flex">
              <v-carousel-item v-for="item in items" :key="item.id">
                <v-card class="p-card   " elevation="10">

                  <v-list-item-avatar class="r-avatar " height="100px" width="100px">
                    <v-img :src="item.img"></v-img>
                  </v-list-item-avatar>
                  <div>
                    <i class="fa-solid fa-star md-5"></i>
                    <i class="fa-solid fa-star md-5"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half-stroke" aria-hidden="true"></i>
                  </div>
                  <v-card-subtitle class="p-description" color="brown" style="color: rgb(70, 0, 7)"><b>{{ item.name
                      }}</b></v-card-subtitle>
                  <v-card-text class="p-description"><v-icon color="blue"> fa-quote-left</v-icon>
                    {{ item.testimonial }}
                    <v-icon color="blue"> fa-quote-right</v-icon></v-card-text>
                </v-card>
              </v-carousel-item>
              <!-- <v-carousel-item>
                <v-card class="p-card  ma-12 " elevation="10" style="background-color: transparent">
                  <v-img contain height="400px" src="../assets/testimonial-twitter1.jpeg"
                    style="align-self: center; justify-self: center;"></v-img>
                </v-card>
              </v-carousel-item>
              <v-carousel-item>
                <v-card class=" p-card ma-12 " elevation=" 10"
                  style="background-color: transparent;justify-items: center;">
                  <v-img contain height="400px" width="100%" style="justify-items: center;"
                    src="../assets/testimonial-twitter2.jpeg"></v-img>
                </v-card>
              </v-carousel-item> -->
            </div>
          </v-carousel>
        </v-col>
        <v-col lg="1" sm="0" md="0" cols="0"></v-col>
      </v-row>
      <!-- </v-slide-group-item>
    </v-slide-group> -->
    </v-sheet>
  </div>
</template>
<script>

export default {
  data: () => ({
    items: [
      {
        id: "0",
        name: "Mr. Basaiwala (CA)",
        img: require("../assets/Nitesh.png"),
        testimonial:
          "Stock selection and highlighting is an niche expertise, and an important element of Rocket Stock Pick. They showcase Results and Value. They highlight the outcomes they deliver and the value to the clients. Happy Investor under their guidance.",
      },
      {
        id: "1",
        name: "Mrs. Kumari  (Manager in Big 4)",
        img: require("../assets/Kumari.png"),
        testimonial:
          "My first trading experience with your guidance is amazing. You figured out the way to convert loss into profit on quite a few occasions. I would like to thank you for all your efforts. I do hope we share great success together👏👌 , keep up the good work😍",
      },
      {
        id: "2",
        name: "Mr. Kaushik (Tech Lead in Big 4  )",
        img: require("../assets/dua.png"),
        testimonial:
          "I started my trading journey with guruji’s, guidance and support. He explains important topics in very simple terms and guidance in stock/ trade selections is amazing.It’s always good to be around him, even a single minute I have spent with him I have learnt something new and valuable. Thanks for your support in my journey."
      },
      {
        id: "3",
        name: "Mr. Sinha (LIC)",
        img: require("../assets/Sinha.png"),
        testimonial:
          "Prediction of Swing well before anyone's prediction is amazing and gives you good RR.Thanks and best wishes @RocketStockPicks",
      },

    ],
  }),

};
</script>

<style scoped>
#top {
  border: 1px solid black;
}

.flexrow {
  display: flex;
  flex-direction: column;
}

.main-testimonial {
  display: flex;
  flex-direction: column;
  column-gap: 30px;
  /* background: rgb(231, 230, 230); */
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 20px;
  min-width: 100vw;
  background-color: rgb(245, 245, 245);


  justify-content: space-between;
  margin-left: 0%;
  margin-top: 3%;

}

.content {
  height: 100vh;
  min-width: 100vw;
  direction: flex;
  justify-content: space-evenly;
  margin: 5%;
  background-color: transparent;
  font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
  font-size: 25px;
}

.contentrow {
  /* background-color: aqua; */
  display: flex;
  justify-content: center;
  margin-left: 20%;
  margin-right: 20%;
  background-color: transparent;
  margin-top: 5%;
}

.person {
  border-radius: 50%;
}

.img-class {
  /* background-color: yellow; */
  max-height: 80%;
  max-width: 10%;
}

.comments {
  /* background-color: yellow; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: transparent;
}

.p-description {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: 22px;
  text-align: left;
  justify-content: safe;
  align-items: center;
  max-width: 95%;
  padding-right: 10px;
  line-height: 1.2;

}

.v-card__text {
  color: white;
}

i {
  color: #ffa534;
  margin-left: 2%;
}

.p-card {

  min-height: 100%;


  background-color: rgb(239, 219, 223);
}

.r-avatar {
  justify-content: center;
  align-items: center;
  justify-items: center;
  align-content: center;

}

.v-btn {
  background-color: black;
}

.v-icon {
  color: black !important;

}

.image-test {
  object-fit: contain;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  contain: size;
}
</style>