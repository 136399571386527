<template>

    <div class="" id="gallery">
        <div class="heading-title"><span>GALLERY</span></div>
        <v-carousel class="carousel-gallery" :show-arrows="false" cycle interval="5000" continuous>
            <v-carousel-item style="height: 100vh;" class="gallery-image" v-for="item in items" :key="item">
                <v-img contain height="100%" width="100vw" :src="item.img" cover></v-img>
            </v-carousel-item>
        </v-carousel>
        <!-- <v-dialog v-model="dialog" width="100px">
            <template v-slot:activator="{ on, attrs }">
                <div class="heading-title"><span>Gallery</span></div>

                <div class="d-flex" style="margin:2%;max-height: 80vh;">
                    <div class="d-flex flex-column">
                        <img class="gallery-image" style="height: 55%;" src="../assets/nilachal.jpg" />
                        <img class="gallery-image" style="height: 44%;" src="../assets/naukri.jpg" />

                    </div>
                    <div class="d-flex flex-column">
                        <img class="gallery-image" style="height: 49.5%;" src="../assets/stock1.jpg" />
                        <img class="gallery-image" style="height: 49.5%;" src="../assets/hblpower.jpg" />
                    </div>
                    <div class="d-flex flex-column">
                        <img v-bind="attrs" v-on="on" @click="bindData('../assets/tatachem950.jpg')"
                            class="gallery-image" style="height: 35%;" src="../assets/trent.jpg" />
                        <img class="gallery-image" style="height: 64%;" src="../assets/tatachem950.jpg" />
                    </div>


                    <v-card max-width="400" prepend-icon="mdi-update"
                        text="Your application will relaunch automatically after the update is complete."
                        title="Update in progress">
                        <v-img :src="url"></v-img>
                        <template v-slot:actions>
                            <v-btn class="ms-auto" text="Ok" @click="dialog = false"></v-btn>
                        </template>
</v-card>

</div>
</template>
</v-dialog> -->
    </div>
</template>


<script>


export default {
    data: () => ({
        items: [
            {
                img: require("../assets/nilachal.jpg"),
            },
            {
                img: require("../assets/naukri.jpg"),
            },
            {
                img: require("../assets/stock1.jpg"),
            },
            {
                img: require("../assets/hblpower.jpg"),
            },
            {
                img: require("../assets/trent.jpg"),
            },
            {
                img: require("../assets/tatachem950.jpg"),
            },


        ],
    }),
    methods: {
        bindData(data) {
            this.url = data,
                this.dialog = true

        }
    }

}
</script>
<style>
.carousel-gallery {
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
}

/* @media screen and (min-width: 600px) {
    .gallery-image {
        max-height: 100%;

    }

    .carousel-gallery {
        height: 60vh;

    }
}

@media screen and (max-width: 600px) {
    .carousel-gallery {
        margin-top: 10%;
        height: 60vh;
        width: 100vw;

    }

    .gallery-image {
        max-height: 100%;
        min-width: 100vw;
    }

    .fill-height {
        flex-shrink: 0;
        height: 100%;
        background-position: inherit;
        justify-self: stretch;
    }

    .carousel-content {

        background-size: 100%;
        min-width: 100%;
        justify-items: stretch;
    } 

}*/
</style>
