<template>
  <v-app>
    <Navbar />

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Navbar from "./components/Navigation-Bar";
export default {
  name: "App",

  data: () => ({
    //
  }),
  components: { Navbar },
};
</script>
<style>
body,
html {
  height: 100%;
  margin: 0;
}

.heading-title {
  align-self: center;
  text-align: center;
  font-size: 1.8vw;
  color: rgb(116, 8, 107);
  margin-top: 10%;
  margin-bottom: 4%;
  letter-spacing: 5%;
  border-bottom: 2px solid rgb(116, 8, 107);
  line-height: 0.1em;
  text-justify: auto;


}

@media screen and (max-width: 1000px) {
  .heading-title {
    font-size: 4vw;
  }
}



.heading-title span {
  background: #fff;
  padding: 0 10px;
}

.section-main {
  margin-top: 5%;
  margin-bottom: 4%;
}

.rocket-content {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  font-size: 25px;


}
</style>
