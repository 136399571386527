<template>
  <div class="foot-r">
    <v-row class="foot-row d-flex">
      <v-col style="color:black;font-size: smaller;" class="col-xs-12" lg="4" sm="12" md="12">
        <span>Copyright</span>
        <v-icon class="mx-4 ">mdi-copyright </v-icon>
        <span> All Right Reserved - RocketStockPicks</span>
      </v-col>

      <v-col class="col-xs-12 d-flex" lg="4" sm="12" md="12">
        <v-btn :href="icon.url" target="_blank" v-for="icon in icons" :key="icon.id" class="mx-4 white--text" icon>
          <v-icon color="black" size="20px">
            {{ icon.id }}
          </v-icon>
        </v-btn>
      </v-col>


      <v-col style="color: black;" class="col-xs-12" lg="4" sm="12" md="12">
        <strong style="color: black;"> email: info@rocketstockpicks.co.in </strong>
      </v-col>
    </v-row>


    <v-row class="foot-row d-flex">
      <v-col class="col-xs-12 d-flex"
        style="justify-content: center;align-content:center;color:black;align-self: center;" lg="12" sm="12" md="12"
        cols="12">
        <p style="font-size:2vh; " class="mr-4">VISITOR COUNT </p>
        <img
          src="https://hitwebcounter.com/counter/counter.php?page=11793020&style=0009&nbdigits=6&type=page&initCount=0"
          title="Counter Widget" Alt="Visit counter For Websites" height="25vh" />
      </v-col>

    </v-row>
    <v-show hidden><!-- hitwebcounter Code START -->
      <a href="https://www.hitwebcounter.com" target="_blank">
        <img src="https://hitwebcounter.com/counter/counter.php?page=11853639&style=0009&nbdigits=6&type=ip&initCount=0"
          title="Counter Widget" Alt="Visit counter For Websites" border="0" /></a> </v-show>
  </div>
</template>

<script>
export default {
  data: () => ({
    icons: [
      {
        id: "fab fa-facebook",
        url: "https://www.facebook.com/RocketStockPicks",
      },
      {
        id: "fab fa-twitter",
        url: "https://twitter.com/bittu_grd",
      },

      {
        id: "fab fa-youtube",
        url: "https://youtube.com/@RocketStockPicks",
      },

      {
        id: "mdi-instagram",
        url: "https://www.instagram.com/rocketstockpicks/",
      },
    ],
  }),
};
</script>

<style scoped>
.foot-r {
  background-size: fill;
  background-color: lightgrey;
  width: 100%;
}

.col {
  flex-wrap: nowrap;
  justify-content: center;
}

strong {
  color: white;
}

.col {
  flex-basis: auto;
}
</style>