<!-- <template>
  <v-app>
    <v-navigation-drawer v-model="drawer" app>
      <v-list nav>
        <v-list-item v-for="item in menuItems" :key="item.title" link>


          <v-list-item-content>
            <v-list-item-title style="justify-self: left;">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>


    </v-navigation-drawer>

    <v-toolbar app>
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      </span>
      <v-toolbar-title>
        <router-link to="/" tag="span" style="cursor: pointer">
          {{ appTitle }}
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn flat v-for="item in menuItems" :key="item.title" :to="item.path">
          <v-icon left dark>{{ item.icon }}</v-icon>
          {{ item.title }}
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>

    <v-content>
      <router-view></router-view>
    </v-content>

  </v-app>

</template>

<script>
//import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",
  data() {
    return {
      appTitle: 'Awesome App',
      drawer: false,
      menuItems: [
        { title: 'HOME', url: '/home', icon: 'home' },
        { title: 'ABOUT US', url: '#about-class', icon: 'picture' },
        { title: 'GALLERY', url: '/signin', icon: 'lock_open' }
      ]
    }
  },
};
</script>
<style></style> -->
<template>
  <div class="containers ">
    <v-navigation-drawer overlay-opacity="0.8" v-model="drawer" app>
      <v-toolbar-title>
        <v-img class="mt-5 mb-10" contain height="10vh" src="../assets/rpslogo.png"></v-img>
      </v-toolbar-title>
      <v-list nav>
        <v-list-item>
          <v-list-item-content>
            <v-btn text @click="scrollItemMobile('about-class')" flat style="color :rgb(116, 8, 107);">
              <h4>ABOUT
                US</h4>
            </v-btn>
            <hr style="color :rgb(116, 8, 107);" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn class=" rocket-nav" @click="scrollItemMobile('gallery')" style="color :rgb(116, 8, 107);" text>
              <h4>Gallery</h4>
            </v-btn>
            <hr style="color :rgb(116, 8, 107);" />
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-btn text href="https://t.me/RocketStockPicks" target="_blank" flat
              style="color :rgb(116, 8, 107) ;justify-self: left;">
              <h4>JOIN US </h4>
            </v-btn>
            <hr style="color :rgb(116, 8, 107);" />
          </v-list-item-content>
        </v-list-item>
      </v-list>


    </v-navigation-drawer>
    <v-toolbar app flat>
      <span class="hidden-sm-and-up">
        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      </span>
      <v-toolbar-title>
        <v-img contain height="10vh" src="../assets/rpslogo.png"></v-img>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items class="hidden-xs-only">
        <v-btn style="color :rgb(116, 8, 107);  " @click="scrollItem('about-class')" class="rocket-nav" text>
          <h4>About US</h4>
        </v-btn>

        <v-btn style="color :rgb(116, 8, 107);  " class=" rocket-nav" @click="scrollItem('gallery')" text>
          <h4>Gallery</h4>
        </v-btn>

        <v-btn style="color :rgb(116, 8, 107);  margin-right: 10vh;" href="https://t.me/RocketStockPicks"
          target="_blank" class=" rocket-nav " text>
          <h4>JOIN US</h4>
        </v-btn>

      </v-toolbar-items>
    </v-toolbar>

    <!-- <div class="justify-center " style="text-align: center; align-self: center; justify-content: center;">
      <v-row class="header-row-class">
        <v-toolbar app>
          <span class="hidden-sm-and-up">
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          </span>
          <v-toolbar-title>

          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items class="hidden-xs-only">
            <v-btn color=" rgb(116, 8, 107);" @click="scrollItem('about-class')" class="rocket-nav" text>
              <h4>About US</h4>
            </v-btn>
           
          </v-toolbar-items>
        </v-toolbar>
        <v-col cols="4" class="">
          <v-img contain height="10vh" width="100%" src="../assets/rpslogo.png"></v-img>
        </v-col>
        <v-col cols=" 8" style="align-self: center; justify-content: right;">
          <v-row></v-row>
          <v-row>
            <v-btn color=" rgb(116, 8, 107);" @click="scrollItem('about-class')" class="rocket-nav" text>
              <h4>About US</h4>
            </v-btn>

            <v-btn class=" rocket-nav" text>
              <h4>Gallery</h4>
            </v-btn>



          </v-row>
          <v-row></v-row>
        </v-col>
      </v-row>
    </div> -->


    <div class="nav-bar">
      <img class="rocket-image" src="../assets/up.gif" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    drawer: false
  }),
  methods: {
    scrollItem(ref) {
      const el = document.getElementById(ref);
      el.scrollIntoView({ behavior: "smooth" });

    },
    scrollItemMobile(ref) {
      this.drawer = false;
      const el = document.getElementById(ref);
      el.scrollIntoView({ behavior: "smooth" });

    }
  }
}

</script>

<style>
.nav-btn {
  color: rgb(116, 8, 107);

}

.containers {

  background-size: cover;
  justify-content: center;


}

.main-contents {
  background-color: rgb(46, 45, 45);
}

.rocket-image {
  height: 100%;
  width: 100%;
  max-height: 600px;
  object-fit: fill;
}

@media screen and (max-width: 700px) {}

.rocket-nav {
  color: rgb(116, 8, 107);
  font-size: 10vh;
  text-decoration-color: rgb(116, 8, 107);
}

.nav-bar {
  object-fit: fill;

}

.nav-btn {
  transform-style: flat;
}
</style>