<template class="section-main">
  <div>
    <v-card>
      <div class="heading-title"><span>
          LEARN MORE FROM VIDEOS
        </span>
      </div>
      <v-expansion-panels mandatory style="margin-top: 3%; margin-bottom: 3%" v-model="panel">
        <v-overlay activator="parent" v-model="overlay" class="align-center justify-center"
          location-strategy="connected" scroll-strategy="block" opacity="0.6">
          <v-progress-circular color="primary" size="50" width="4">please wait...</v-progress-circular>
        </v-overlay>
        <v-expansion-panel v-for="(detail, index) in map" :key="index" :value="detail.id"
          @click="readAuthors(detail.id)">
          <v-expansion-panel-header>
            <div style="text-align: center; color: rgb(116, 8, 107)">
              {{ detail.title }}
            </div>
            <template v-slot:actions>
              <v-icon style="align-self: center;" color="green">fa-arrow-down</v-icon>
            </template>

          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="m-5" style="margin-bottom: 2%; margin-top: 2%; text-align: center"></div>
            <v-row no-gutters>
              <v-col cols="12" sm="6" xs="6" v-for="(item, index) in expandable.items" :key="index">
                <iframe width="95%" height="315" :src="'https://youtube.com/embed/' + item.contentDetails.videoId
        " title="YouTube video player" frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen></iframe>
              </v-col>
            </v-row>

            <v-row justify="center" style="margin-bottom: 3%; margin-top: 3%"><v-btn :href="'https://youtube.com/playlist?list=' +
        detail.id +
        '&feature=shared'
        " class="text-white" style=" background-color: rgb(116, 8, 107)" target="_blank"><span style="color: white;">
                  VIEW
                  ALL</span>
              </v-btn></v-row>

          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      expandable: {},
      map: [
        {
          title: "TRADING CONCEPTS",
          id: "PLsHNXHp8BW0B4Ds23U0s55C_RCV1GLYKS",
        },
        {
          title: "SIMPLE TRADING STRATEGIES",
          id: "PLsHNXHp8BW0DMVEJdD1yYZCSCPhsHJoR2",
        },
        {
          title: "OPTIONS TRADING",
          id: "PLsHNXHp8BW0CXVaBcuA0pbWdRkVRYUROC",
        },
        {
          title: "OVERALL MARKET UPDATE",
          id: "PLsHNXHp8BW0CfUo5M5P6LTdZMZKOJD39l",
        },
        {
          title: "ROCKET SUCCESS STORIES",
          id: "PLsHNXHp8BW0DFnPJeIA9rprCj4f-7X70o",
        },
      ],
      overlay: false,
    };
  },
  methods: {
    readAuthors: async function (id) {
      this.overlay = true;
      axios
        .get(
          "https://youtube.googleapis.com/youtube/v3/playlistItems?playlistId=" +
          id +
          "&key=AIzaSyDL-9ERf_tB09pDtdv-De2Cfyv7BIlA8UQ&part=contentDetails&maxResults=2"
        )
        .then((response) => {
          console.log(response.data);
          this.expandable = response.data;
          this.overlay = false;

        })
        .catch((error) => {
          console.log(error);
          this.overlay = false;
        });
    },
  },
  mounted() {
    axios
      .get(
        "https://youtube.googleapis.com/youtube/v3/playlistItems?playlistId=PLsHNXHp8BW0B4Ds23U0s55C_RCV1GLYKS&key=AIzaSyDL-9ERf_tB09pDtdv-De2Cfyv7BIlA8UQ&part=contentDetails&maxResults=2"
      )
      .then((response) => {
        this.expandable = response.data;
        console.log(this.expandable);
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style scoped>
.card {
  min-width: 90%;
}

.main-row {
  height: 100%;
  justify-content: space-evenly;
}

.container {
  height: 100%;
}
</style>