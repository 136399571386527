import { render, staticRenderFns } from "./Free-Youtube-Videos.vue?vue&type=template&id=60aa909c&scoped=true&class=section-main"
import script from "./Free-Youtube-Videos.vue?vue&type=script&lang=js"
export * from "./Free-Youtube-Videos.vue?vue&type=script&lang=js"
import style0 from "./Free-Youtube-Videos.vue?vue&type=style&index=0&id=60aa909c&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60aa909c",
  null
  
)

export default component.exports